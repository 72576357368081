<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='text-sm'>
        <div class='flex flex-col lg:flex-row justify-start text-left w-full items-start'>
          © Samsung Fire & Marine Insurance Co., Ltd. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'SamsungfireLayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
